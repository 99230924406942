var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex",class:_vm.aspectRatio<2/3
    ?'flex-column justify-space-between'
    :'flex-column flex-wrap justify-center',attrs:{"fluid":"","id":"bottom-page"}},[_c('div',{staticClass:"bottom-page-top-cirlce hideOnLandscape"},[_c('v-img',{attrs:{"contain":"","src":require("../assets/2-half-circle-down-black.png")}})],1),_c('div',{staticClass:"body-text-bp ",class:_vm.aspectRatio<2/3
      ?'mt-2'
      :'order-2'},[_vm._v(" From building personalized user experiences to virtual assistants for back-office automation, TacoAI reduces scaling costs throughout the Prosperum ecosystem. ")]),_c('div',{staticClass:"bottom-page-taco-image",class:_vm.aspectRatio<2/3
      ?''
      :'order-1 d-flex align-center'},[_c('v-img',{attrs:{"src":require("../assets/singletaco.png")}})],1),_c('div',{staticClass:"body-text-bp",class:_vm.aspectRatio<2/3
      ?''
      :'order-3'},[_vm._v(" Built on top of and extending Microsoft technologies, Taco AI provides a new client experience and connects data from multiple streams to unify the Prosperum back-office infrastructure. ")]),_c('div',{staticClass:"body-text-bp",class:_vm.aspectRatio<2/3
        ?'mb-6'
        :'order-4'},[_vm._v(" Taco AI is now building out chatbot systems for customer service, a revolutionary AI-powered trading experience, and creating an insights engine to glean connections between data. ")])])}
var staticRenderFns = []

export { render, staticRenderFns }